import { GetStaticProps, NextPage } from 'next';

import { isSearchedItems } from '@/components/layout/SearchProvider';
import { Seo } from '@/components/shared/Seo';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';
import { PageTemplate } from '@/components/storyblok/PageTemplate';

import { defaultLang, Locale } from '@/hooks/useLang';

import { relations } from '@/lib/api/relations';
import { getDataSourceEntries, getFooter, getHeader, getPage } from '@/lib/api/utils';
import { getSearchedItems } from '@/lib/api/utils/getSearchedItems';
import { SlugParams, ContainerProps } from '@/lib/types';
import { getAsideData, getLocalePrefix } from '@/lib/utils';
import { getBottomSliderData } from '@/lib/utils/getBottomSliderData';
import { getSeoData } from '@/lib/utils/getSeoData';

const Error404Page: NextPage<ContainerProps> = ({ data, preview }) => {
  const seo = getSeoData(data);

  if (!data) {
    return null;
  }

  return (
    <>
      <Seo seo={seo} />
      <PageTemplate data={data} preview={preview}>
        {(blok) => <BlokProvider blok={blok as BlokDynamicZone} />}
      </PageTemplate>
    </>
  );
};

export const getStaticProps: GetStaticProps<ContainerProps, SlugParams> = async ({
  preview = false,
  locale: lang = defaultLang
}) => {
  const locale = lang.toLowerCase() as Locale;
  const localePrefix = getLocalePrefix(locale);

  const contentNodes = await getSearchedItems({ startsWith: `${localePrefix}/`, searchTerm: '' });
  const searchedItems = isSearchedItems(contentNodes?.items) ? contentNodes?.items : null;

  const datasourceEntries = await getDataSourceEntries({ locale });

  const footerData = await getFooter({ slug: `${localePrefix}/layout/footer` });
  const headerData = await getHeader({ slug: `${localePrefix}/layout/header` });
  const data = await getPage({ slug: `${localePrefix}/layout/404`, relations });
  const bottomSliderData = await getBottomSliderData(data?.content?.bottomSliderType, localePrefix);

  const withAsideLinks = data?.content?.withAsideLinks ?? true;

  const aside = await getAsideData({ slug: '/', locale, withAsideLinks });

  return {
    props: {
      data,
      headerData,
      footerData,
      preview,
      searchedItems,
      datasourceEntries,
      bottomSliderData,
      aside
    }
  };
};

export default Error404Page;
